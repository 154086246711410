body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #e0e0e0;
}

.markDown img {
  max-width: 100%;
}

.timelineElement {
  height: auto;
  pointer-events: none;
}

@media only screen and (min-width:1170px) {

  .timelineElement {
    height: 320px;
  }
}

.timelineElement:last-child {
  height: 600px;
}

.header {
  background: #3A7D8C;
  color: #fff;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 0px;
  margin-bottom: 20px;
  font-family: 'Nunito';
  font-size: 20px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}