body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #e0e0e0;
}

.markDown img {
  max-width: 100%;
}

.timelineElement {
  height: auto;
  pointer-events: none;
}

@media only screen and (min-width:1170px) {

  .timelineElement {
    height: 320px;
  }
}

.timelineElement:last-child {
  height: 600px;
}

.header {
  background: #3A7D8C;
  color: #fff;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 0px;
  margin-bottom: 20px;
  font-family: 'Nunito';
  font-size: 20px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}
/* desert scheme ported from vim to google prettify */
pre.prettyprint { display: block; background-color: #333 }
pre .nocode { background-color: none; color: #000 }
pre .str { color: #ffa0a0 } /* string  - pink */
pre .kwd { color: #f0e68c; font-weight: bold }
pre .com { color: #87ceeb } /* comment - skyblue */
pre .typ { color: #98fb98 } /* type    - lightgreen */
pre .lit { color: #cd5c5c } /* literal - darkred */
pre .pun { color: #fff }    /* punctuation */
pre .pln { color: #fff }    /* plaintext */
pre .tag { color: #f0e68c; font-weight: bold } /* html/xml tag    - lightyellow */
pre .atn { color: #bdb76b; font-weight: bold } /* attribute name  - khaki */
pre .atv { color: #ffa0a0 } /* attribute value - pink */
pre .dec { color: #98fb98 } /* decimal         - lightgreen */

/* Specify class=linenums on a pre to get line numbering */
ol.linenums { margin-top: 0; margin-bottom: 0; color: #AEAEAE } /* IE indents via margin-left */
li.L0,li.L1,li.L2,li.L3,li.L5,li.L6,li.L7,li.L8 { list-style-type: none }
/* Alternate shading for lines */
li.L1,li.L3,li.L5,li.L7,li.L9 { }

@media print {
    pre.prettyprint { background-color: none }
    pre .str, code .str { color: #060 }
    pre .kwd, code .kwd { color: #006; font-weight: bold }
    pre .com, code .com { color: #600; font-style: italic }
    pre .typ, code .typ { color: #404; font-weight: bold }
    pre .lit, code .lit { color: #044 }
    pre .pun, code .pun { color: #440 }
    pre .pln, code .pln { color: #000 }
    pre .tag, code .tag { color: #006; font-weight: bold }
    pre .atn, code .atn { color: #404 }
    pre .atv, code .atv { color: #060 }
}

pre {
    white-space: pre-wrap;
    padding: 15px !important;
}



