/* desert scheme ported from vim to google prettify */
pre.prettyprint { display: block; background-color: #333 }
pre .nocode { background-color: none; color: #000 }
pre .str { color: #ffa0a0 } /* string  - pink */
pre .kwd { color: #f0e68c; font-weight: bold }
pre .com { color: #87ceeb } /* comment - skyblue */
pre .typ { color: #98fb98 } /* type    - lightgreen */
pre .lit { color: #cd5c5c } /* literal - darkred */
pre .pun { color: #fff }    /* punctuation */
pre .pln { color: #fff }    /* plaintext */
pre .tag { color: #f0e68c; font-weight: bold } /* html/xml tag    - lightyellow */
pre .atn { color: #bdb76b; font-weight: bold } /* attribute name  - khaki */
pre .atv { color: #ffa0a0 } /* attribute value - pink */
pre .dec { color: #98fb98 } /* decimal         - lightgreen */

/* Specify class=linenums on a pre to get line numbering */
ol.linenums { margin-top: 0; margin-bottom: 0; color: #AEAEAE } /* IE indents via margin-left */
li.L0,li.L1,li.L2,li.L3,li.L5,li.L6,li.L7,li.L8 { list-style-type: none }
/* Alternate shading for lines */
li.L1,li.L3,li.L5,li.L7,li.L9 { }

@media print {
    pre.prettyprint { background-color: none }
    pre .str, code .str { color: #060 }
    pre .kwd, code .kwd { color: #006; font-weight: bold }
    pre .com, code .com { color: #600; font-style: italic }
    pre .typ, code .typ { color: #404; font-weight: bold }
    pre .lit, code .lit { color: #044 }
    pre .pun, code .pun { color: #440 }
    pre .pln, code .pln { color: #000 }
    pre .tag, code .tag { color: #006; font-weight: bold }
    pre .atn, code .atn { color: #404 }
    pre .atv, code .atv { color: #060 }
}

pre {
    white-space: pre-wrap;
    padding: 15px !important;
}


